<template>
  <v-card flat >
    <div class="d-flex flex-column justify-center align-center pt-10">  
      <div class="">
        <v-avatar v-if="!data.image" size="120" color="secondary"/>
        <v-avatar v-else size="120" color="secondary">
          <v-img :src="`${data.aws}/users/${data.uuid}/image/${data.image.image}`"></v-img>
        </v-avatar>
      </div>
      <div class="text-h5 font-weight-bold text-center mt-5">
        {{data.firstname + ' ' + data.lastname}}
        <div class="body-2 grey--text">{{$helpers.level(data.level)}}</div>
      </div>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Score</th>
          </tr>
        </thead>
        <tbody>
          <tr class="secondary">
            <td>TOTAL POINTS</td>
            <td class="font-weight-bold">{{data.rank.total_score}} pts</td>
          </tr>
          <tr class="secondary-1">
            <td>CHAT POINTS</td>
            <td class="caption">{{data.rank.chat_points}} pts</td>
          </tr>
          <tr v-for="item in data.rank.booth_scores" :key="item.id" >
            <td>
              {{ item.booth_name }} 
              <v-icon :color="
                item.booth_type == 'GOLD' ? 'warning' 
                : item.booth_type == 'SILVER' ? '#999' 
                : 'brown' 
              ">
                mdi-circle-medium
              </v-icon>
            </td>
            <td class="caption">{{ item.score }} pts</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: ['data']
}
</script>